import { LuShirt } from "react-icons/lu";
import { TbTruckDelivery } from "react-icons/tb";
import { TbDiscount2 } from "react-icons/tb";
import { CiBadgeDollar } from "react-icons/ci";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import newsLetterImg from '../../assets/images/newsletter.png';
import Button from '@mui/material/Button';
import { IoMailOutline } from "react-icons/io5";


const Footer = () => {
    return (
        <>
            <section className="newsLetterSection mt-3 mb-3 d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="text-white mb-1"></p>
                            <h3 className="text-white">Join our newsletter and get...</h3>
                            <p className="text-light">Join our email subscription now to get updates on<br /> promotions and coupons.</p>


                            <form className="mt-4">
                                <IoMailOutline />
                                <input type="text" placeholder="Your Email Address" />
                                <Button>Subscribe</Button>
                            </form>

                        </div>

                        <div className="col-md-6">
                            <img src={newsLetterImg} />
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="topInfo row">
                        <div className="col d-flex align-items-center">
                            <span><LuShirt /></span>
                            <span className="ml-2">Trending products</span>
                        </div>

                        <div className="col d-flex align-items-center">
                            <span><TbTruckDelivery /></span>
                            <span className="ml-2">Free delivery for order over ₹3000</span>
                        </div>

                        <div className="col d-flex align-items-center">
                            <span><TbDiscount2 /></span>
                            <span className="ml-2">Daily Mega Discounts</span>
                        </div>

                        <div className="col d-flex align-items-center">
                            <span><CiBadgeDollar /></span>
                            <span className="ml-2">Best price on the market</span>
                        </div>
                    </div>



                    <div className="row mt-5 linksWrap">
                        <div className="col">
                            <h5>TOP CATEGORIES</h5>
                            <ul>
                                <li><Link to="#">Ethnic Wear</Link></li>
                                <li><Link to="#">Western Wear</Link></li>
                                <li><Link to="#">Ethnic Wear</Link></li>
                                <li><Link to="#">Kurtis, Tunics & Tops</Link></li>
                                <li><Link to="#">Dress Materials</Link></li>
                                <li><Link to="#">Dresses</Link></li>
                                <li><Link to="#">Co-ords Sets</Link></li>
                            </ul>
                        </div>


                        <div className="col">
                            <h5>CUSTOMER SERVICE</h5>
                            <ul>
                                <li><Link to="#">Returns & Cancellation</Link></li>
                                <li><Link to="#">FAQs</Link></li>
                                <li><Link to="#">Contact us</Link></li>
                                <li><Link to="#">Blogs</Link></li>
                            </ul>
                        </div>

                        <div className="col">
                            <h5>MY PROFILE</h5>
                            <ul>
                                <li><Link to="#">My Account</Link></li>
                                <li><Link to="#">Track Order</Link></li>
                                <li><Link to="#">My Cart</Link></li>
                                <li><Link to="#">Wishlist</Link></li>
                                <li><Link to="#">Order History</Link></li>
                                
                            </ul>
                        </div>

                        <div className="col">
                            <h5>QUICK LINKS</h5>
                            <ul>
                                <li><Link to="#">Shipping Policy</Link></li>
                                <li><Link to="#">Privacy Policy</Link></li>
                                <li><Link to="#">About Us</Link></li>
                                <li><Link to="#">Business Enquiry</Link></li>
                                
                            </ul>
                        </div>
                    </div>



                    <div className="copyright mt-3 pt-3 pb-3 d-flex">
                        <p className="mb-0">Copyright 2024. All rights reserved Created By Akarshit Bathla</p>
                        <ul className="list list-inline ml-auto mb-0 socials">
                            <li className="list-inline-item">
                                <Link to="#"><FaFacebookF /></Link>
                            </li>

                            <li className="list-inline-item">
                                <Link to="#"><FaTwitter /></Link>
                            </li>

                            <li className="list-inline-item">
                                <Link to="https://www.instagram.com/so.suity_/"><FaInstagram /></Link>
                            </li>
                        </ul>
                    </div>


                </div>
            </footer>
        </>
    )
}

export default Footer;